<template>
    <div>
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CCard class="card-cliente">
            <CCardHeader class="text-center bg-dark text-white">
                <b class="w-50">{{$t('label.NewTransportationCompany')}}</b>
                <CButton class="float-right" size="sm" @click="cerrar" > X </CButton>
            </CCardHeader>
            <CCardBody>
                <CRow class="mt-3 mb-3 justify-content-center">
                    <CCol sm="9">
                        <CRow>
                            <CCol sm="4" class="text-right mt-2 pr-1">
                                <label><b class="text-danger">* </b>{{  $t('label.rifNumber') }}</label>
                            </CCol>
                            <CCol sm="3" class="px-1"><!-- rif -->
                                <CSelect
                                    v-uppercase
                                    size="sm"
                                    v-model="LetraRif"
                                    :value.sync="LetraRif"
                                    :options="LetraOptions"
                                    :is-valid="hasError($v.LetraRif)"
                                    :invalid-feedback="errorMessage($v.LetraRif)"
                                />
                            </CCol>
                            <CCol sm="5"> <!-- rif -->
                                <CInput
                                    v-uppercase
                                    size="sm"
                                    v-model.trim="$v.ClientRif.$model"
                                    ref="ClientRif"
                                    maxlength="9"
                                    minlength="9"
                                    :is-valid="hasError($v.ClientRif)"
                                    :invalid-feedback="errorMessage($v.ClientRif)"
                                    @blur="$v.$touch()"
                                    :placeholder="$t('label.rifNumber')"
                                />
                            </CCol>
                        
                            <CCol sm="12" style="margin-top:20px;"><!-- nombre -->
                                <CInput
                                    :label="$t('label.name')"
                                    :horizontal="{label: 'col-sm-4', input:'col-sm-8'}"
                                    v-uppercase
                                    size="sm"
                                    add-label-classes="required text-right"
                                    :invalid-feedback="errorMessage($v.ClientName)"
                                    maxlength="250"
                                    v-model.trim="$v.ClientName.$model"
                                    :is-valid="hasError($v.ClientName)"
                                    :placeholder="$t('label.clientName')"                
                                />
                            </CCol>
                            <CCol sm="12" style="margin-top:20px;"><!-- representante -->
                                <CInput
                                    :label="$t('label.representative')"
                                    :horizontal="{label: 'col-sm-4', input:'col-sm-8'}"
                                    v-uppercase
                                    size="sm"
                                    add-label-classes="required text-right"
                                    :invalid-feedback="errorMessage($v.Representative)"
                                    maxlength="50"
                                    v-model.trim="$v.Representative.$model"
                                    :is-valid="hasError($v.Representative)"
                                    :placeholder="$t('label.clientRepresentativeName')"                
                                />
                            </CCol>
                            <CCol sm="12" style="margin-top:20px;"><!-- direccion -->
                                <CTextarea
                                    :label="$t('label.address')"
                                    :horizontal="{label: 'col-sm-4', input:'col-sm-8'}"
                                    v-uppercase
                                    size="sm"
                                    add-label-classes="required text-right"
                                    :invalid-feedback="errorMessage($v.ClientAddress)"
                                    rows="2"
                                    v-model.trim="$v.ClientAddress.$model"
                                    :is-valid="hasError($v.ClientAddress)"
                                    :placeholder="$t('label.clientAddress')"  
                                    maxlength="500"              
                                />
                            </CCol>
                            <CCol sm="12" style="margin-top:20px;">
                                <CInput
                                    :label="$t('label.mainPhone')"
                                    :horizontal="{label: 'col-sm-4', input:'col-sm-8'}"
                                    v-uppercase
                                    size="sm"
                                    add-label-classes="required text-right"
                                    ref="PrimaryPhone"
                                    maxlength="15"
                                    minlength="11"
                                    v-model.trim="$v.PrimaryPhone.$model"
                                    :is-valid="hasError($v.PrimaryPhone)"
                                    :invalid-feedback="errorMessage($v.PrimaryPhone)"
                                    :placeholder="$t('label.mainPhone')"                
                                />
                            </CCol>
                            <CCol sm="12" style="margin-top:20px;"> <!-- tlf  2 -->
                                <CInput
                                    :label="$t('label.secondaryPhone')"
                                    :horizontal="{label: 'col-sm-4', input:'col-sm-8'}"
                                    v-uppercase
                                    size="sm"
                                    add-label-classes="text-right"
                                    maxlength="15"
                                    minlength="11"
                                    v-model.trim="$v.SecondaryPhone.$model"
                                    :is-valid="hasError($v.SecondaryPhone)"
                                    :invalid-feedback="errorMessage($v.SecondaryPhone)"
                                    :placeholder="$t('label.secondaryPhone')"                
                                />
                            </CCol>
                            <CCol sm="12" style="margin-top:20px;"><!-- email1 -->
                                <CInput
                                    :label="$t('label.mainEmail')"
                                    :horizontal="{label: 'col-sm-4', input:'col-sm-8'}"
                                    v-uppercase
                                    size="sm"
                                    add-label-classes="required text-right"
                                    maxlength="100"
                                    v-model.trim="$v.PrimaryEmail.$model"
                                    :is-valid="hasError($v.PrimaryEmail)"
                                    :invalid-feedback="errorMessage($v.PrimaryEmail)"
                                    :placeholder="$t('label.mainEmail')"                
                                />
                            </CCol>
                            <CCol sm="12" style="margin-top:20px;"><!-- email2 -->
                                <CInput
                                    :label="$t('label.secondaryEmail')"
                                    :horizontal="{label: 'col-sm-4', input:'col-sm-8'}"
                                    v-uppercase
                                    size="sm"
                                    add-label-classes=" text-right"
                                    maxlength="100"
                                    :invalid-feedback="errorMessage($v.SecondaryEmail)"
                                    v-model.trim="$v.SecondaryEmail.$model"
                                    :is-valid="hasError($v.SecondaryEmail)"
                                    :placeholder="$t('label.secondaryEmail')"                
                                />
                            </CCol>
                        </CRow>
                    </CCol>
                    <CCol sm="auto">
                        <CRow>
                            <CCol sm="12">
                                <CButton size="sm" color="add"  class="mr-1"  v-c-tooltip="{content: $t('label.grabar'), placement: 'top-end'}" @click="guardar" :disabled="isSubmit">
                                    <CIcon name="checkAlt" />
                                </CButton>

                                <CButton color="wipe" class="mr-1" size="sm" v-c-tooltip="{content: $t('label.clearFields'), placement: 'top-end'}" @click="limpiarDatos" :disabled="isSubmit">
                                    <CIcon name="cil-brush-alt"/>
                                </CButton>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
    import ClienteValidations from '@/_validations/cliente/clienteValidations';
    import UpperCase  from '@/_validations/uppercase-directive';
    import ModalMixin from '@/_mixins/modal';
    import { mapState } from 'vuex';
    function data () {
        return {
            //MODELO
            LetraRif: 'J',
            ClientRif: '',
            ClientName: '',
            ClientAddress: '',
            PrimaryPhone: '',
            SecondaryPhone: '',
            PrimaryEmail: '',
            SecondaryEmail: '',
            Representative: '',
            FgActClient: '1',
            Status: 1,
            originStatus: 1,
            UserId: '',
            loadingOverlay: false,    
            LetraOptions: ['J','G','P','V'],
            tituloModal: '',
            showModal:false,
            selectOptions: [
                { 
                    value: 1, 
                    label: this.$t('label.ACTIVO')
                },
                { 
                    value: 0, 
                    label: this.$t('label.INACTIVO')
                }
            ],
        }
    }
    //METHOD
    function limpiarDatos() {
        this.ClientRif = '';
        this.Representative = '';
        this.ClientAddress = '';
        this.ClientName = '';
        this.Status= 1;
        this.$nextTick(() => { this.$v.$reset() })
    }
    function limpiarContacto() {
        this.PrimaryEmail = '';
        this.SecondaryEmail = '';
        this.PrimaryPhone = '';
        this.SecondaryPhone = '';
        this.$nextTick(() => { this.$v.$reset() })
    }
    function cerrar(){
        this.$emit('child-refresh', false);
        this.showModal = false;
    }
    function guardar () {
        // if (this.$v.$invalid) return false;
        try {
            this.loadingOverlay = true;
            this.$v.$touch();

            if (this.$v.$error) {
                this.loadingOverlay = false;
                throw this.$t('label.errorsPleaseCheck');
            }
            let rif = this.LetraRif+this.ClientRif;
            let ClientJson = {
                ClientRif: rif,
                ClientName: this.ClientName,
                ClientAddress: this.ClientAddress,
                PrimaryEmail: this.PrimaryEmail,
                SecondaryEmail: this.SecondaryEmail,
                PrimaryPhone: this.PrimaryPhone,
                SecondaryPhone: this.SecondaryPhone,
                Representative: this.Representative,
                UserId: this.user.UserId,
            };

            let metodo = 'POST';
            let ruta = "ClientCarrier-insert";

            this.$http.ejecutar(metodo, ruta, ClientJson, { root: 'ClientJson' })
            .then(response => {
                let res = [...response.data.data];
                this.limpiarDatos();
                this.limpiarContacto();
                this.$notify({
                    group: 'container',
                    title: '¡'+this.$t('label.success')+'!',
                    text: res[0].Response,
                    type: "success"
                });
                this.loadingOverlay= false;
                this.$emit('child-refresh', true);
                this.showModal = false;
            }).catch((err) => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
                this.loadingOverlay= false;
            }).then(() => {
                this.isSubmit = false;
            });
        } catch (e) {
            this.notifyError({ text: e });
        }
    }

    //COMPUTED
    function isDisabled() {
        return this.$v.$invalid
    }

    export default {
        name:'ClienteCollapse',
        data,
        mixins: [ModalMixin],
        directives: UpperCase,
        validations: ClienteValidations,
        methods:{
            limpiarDatos,
            limpiarContacto,
            guardar,
            cerrar,
        },
        mounted(){
            this.showModal = false;
        },
        props: {
            modal: null
        },
        computed: {
            ...mapState({
                user: state => state.auth.user,
            }),  
        },
        watch:{
            modal: function () {
                if (this.modal) {
                    this.showModal = true;
                    if (this.modal==true) {
                        this.limpiarDatos();
                        this.limpiarContacto();
                        this.originStatus = 1;
                        this.Status = 1;
                    }
                    this.$emit('cerrarModal');
                }
                if (this.modal==false) 
                this.$v.$touch(); 
            }
        }
    }
</script>

<style lang="scss">
    .modal-content-client {
        .modal-content {
            width: 80% !important;
            margin: auto !important;
            .modal-body {
                padding-left: 2.7rem;
                padding-right: 2.7rem;
            }
        }
    }
</style>